<template>
    <li :class="['message', {'pick-out': isImportant}, {bold: isNew}]">
        <div class="row">
            <span class="date">{{dateString}}</span>
            <span class="title" @click="open()">{{title}}</span>
        </div>
        <transition name="slide" mode="in-out">
            <div v-if="isShow" class="content">
                <section class="text" v-html="text" />
                <span>
                    <v-icon v-for="(item, key) in files" :key="key" @click="$emit('download', {id: item.id, name: item.origin_name})">
                        {{/*item.origin_name.split(".")[1].match("pdf") ? "note-blank" : */"note"}}
                    </v-icon>
                </span>
            </div>
        </transition>
    </li>
</template>

<script>
    import VIcon from "../icons/VIcon";
    import dateFormatter from "@/modules/module.date";
    export default {
        name: "RwmMessage",
        components: {VIcon},
        props: {
            title: String,
            date: [String, Number],
            isNew: Boolean,
            isImportant: Boolean,
            text: String,
            files: Array,
            messageId: [String, Number]
        },
        computed: {
          dateString() {
            return dateFormatter(this.date)
          }
        },
        data: () => ({
            isShow: false
        }),
        methods: {
            open() {
                this.isShow=!this.isShow
                if (this.isNew) {this.$emit("read", this.messageId)}
            }
        }
    }
</script>

<style lang="scss">
    .message {
        color: $RWM-grey;

        font-weight: normal;
        font-size: 19px;
        line-height: 24px;
        text-align: left;

        display: flex;
        flex-wrap: wrap;
        column-gap: 44px;

        border-bottom: 1px solid $RWM-grey-light;

        cursor: pointer;
        & .row {
            padding: 12px 0;
        }
        & .title {
            cursor: pointer;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                height: 1px;
                left: 0;
                right: 0;
                background-color: $RWM-grey;
                bottom: -1px;
            }
       }
        &.pick-out {
            color: $RWM-red;
            & .title {
                &:after {
                    background-color: $RWM-red;
                }
            }
        }
        &.bold {
            & .title, & .date {
                font-weight: 600;
            }
            & .title {
                &:after {
                    height: 2px;
                }
            }
        }
        & .content{
            color: $RWM-grey;
            font-size: 17px;
            line-height: 22px;
            margin-left: 160px;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
            align-items: flex-start;
            padding: 19px 0 15px 0;
            transition: all 300ms;
        }
    }
</style>
